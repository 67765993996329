@font-face {
    font-family: 'HelveticaforTarget-Light';
    src: url('../assets/fonts/Light/HelveticaforTarget-Light.eot?#iefix');
    src: url('../assets/fonts/Light/HelveticaforTarget-Light.eot?#iefix') format('eot'), url('../assets/fonts/Light/HelveticaforTarget-Light.woff') format('woff'),
        url('../assets/fonts/Light/HelveticaforTarget-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'TargetHelvitica';
    src: url('../assets/fonts/HelveticaforTarget-Bold.woff') format('woff');
}

@font-face {
    font-family: 'HelveticaforTargetRegular';
    src: url('../assets/fonts/HelveticaNeueforTarget-Rm.woff') format('woff');
}
@font-face {
    font-family: 'HelveticaforTargetBold';
    src: url('../assets/fonts/HelveticaforTarget-Bold.woff') format('woff');
}
